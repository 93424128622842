<template>
  <div class="main_content client_activity_main align-items-center" style="min-height: 80vh">
    <div class="row justify-content-center">
      <div class="top_heading ">
        <div class="row justify-content-xl-between">
          <div class="col-12 col-xl-3 col-xxl-5 mb-3">
            <div class="heading">
              <h2 class="client_top">Client Activity</h2>
              <h3 class="client_dash_top">PBE Management Dashboard</h3>
            </div>
          </div>
          <div class="col-12 col-md-12 col-xl-9 col-xxl-7">
            <div class="align-items-center d-flex justify-content-end w-100">
              <div class="date_toolbar_main">
                <div class="bg-white date-toolbar region_bar_add">
                  <div class="row me-0">
                    <div class="col-xs-12 col-md-12 col-lg-9 align-self-center">
                      <div class="">
                        <div class="bg-white date-toolbar" style="box-shadow: none">
                          <div class="row">
                            <div class="col-xs-12 col-md-12 align-self-center">
                              <div class="row">
                                <div class="col-xs-12 col-md-12 col-lg-6">
                                  <div class="date">
                                    <div class="heading_5">From</div>
                                    <div class="text_black font_size_14 d-flex align-items-center">
                                      <span>
                                        <svg xmlns="http://www.w3.org/2000/svg"
                                          width="17.5" height="17.5"
                                          viewBox="0 0 17.5 17.5">
                                          <path data-name="Path 131" d="M7.444 6.556V3m7.111 3.556V3m-8 7.111h8.889M4.778 19h12.444A1.778 1.778 0 0 0 19 17.222V6.556a1.778 1.778 0 0 0-1.778-1.778H4.778A1.778 1.778 0 0 0 3 6.556v10.666A1.778 1.778 0 0 0 4.778 19z" transform="translate(-2.25 -2.25)" style=" fill: none; stroke-linecap: round; stroke-linejoin: round; stroke-width: 1.5px; " />
                                        </svg>
                                      </span>
                                      <datepicker placeholder="MM DD, YYYY"
                                        v-model="listConfig.start_date"
                                        :format="customFormatter"
                                        @selected="changeStartDate"
                                        :disabledDates="disabledFutureDates">
                                      </datepicker>
                                      <p class="min-date-value d-none">
                                        {{ listConfig.start_date | formatDate }}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-xs-12 col-md-12 col-lg-6">
                                  <div class="date todate pl_6">
                                    <div class="heading_5">To</div>
                                    <div class="text_black font_size_14 d-flex align-items-center">
                                      <span>
                                        <svg xmlns="http://www.w3.org/2000/svg"
                                          width="17.5" height="17.5"
                                          viewBox="0 0 17.5 17.5">
                                          <path data-name="Path 131" d="M7.444 6.556V3m7.111 3.556V3m-8 7.111h8.889M4.778 19h12.444A1.778 1.778 0 0 0 19 17.222V6.556a1.778 1.778 0 0 0-1.778-1.778H4.778A1.778 1.778 0 0 0 3 6.556v10.666A1.778 1.778 0 0 0 4.778 19z" transform="translate(-2.25 -2.25)" style=" fill: none; stroke-linecap: round; stroke-linejoin: round; stroke-width: 1.5px; " />
                                        </svg>
                                      </span>
                                      <datepicker placeholder="MM DD, YYYY"
                                        v-model="listConfig.end_date"
                                        :format="customFormatter"
                                        @selected="changeEndDate"
                                        :disabled="isDisabled"
                                        :disabledDates="disabledDates">
                                      </datepicker>
                                      <p class="max-date-value d-none">
                                        {{ listConfig.end_date | formatDate }}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-xs-12 col-md-12 col-lg-3 align-self-center">
                      <div class="form-group for_ipad">
                        <v-select @input="filterMainData"
                          :reduce="(region_list) => region_list.id" :options="region_list"
                          label="name" placeholder="By Region" name="Region"
                          v-model="listConfig.regionBy" v-validate="'required'" browserAutocomplete="false" autocomplete="off" />
                        <small class="error text-danger"
                          v-show="errors.has('addUserForm.Region')">
                        {{ errors.first("addUserForm.Region") }}
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <a href="javascript:" class="reset_btn" @click="resetDatesMain">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                  version="1.1" x="0px" y="0px" viewBox="0 0 1000 1000"
                  enable-background="new 0 0 1000 1000" xml:space="preserve">
                  <metadata>
                    Svg Vector Icons : http://www.onlinewebfonts.com/icon
                  </metadata>
                  <g>
                    <path
                      d="M829.7,134.7C740.5,59,624.7,10,500,10C228.3,10,10,228.3,10,500h89.1C99.1,277.3,277.3,99.1,500,99.1c102.5,0,196,35.7,262.8,98l-75.7,71.3l267.3,49L914.3,50.1L829.7,134.7z M179.3,869.7C268.3,945.5,379.7,990,500,990c271.7,0,490-218.3,490-490h-89.1c0,222.7-178.2,400.9-400.9,400.9c-98,0-187.1-35.7-253.9-89.1l84.7-80.2l-267.3-49l40.1,267.3L179.3,869.7z" />
                  </g>
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="most_visited"> 
        <div class="row">
          <div class="col-xl-6 col-12 position-relative">
            <div style="position: absolute;left: 0;right: 0;top: 0;bottom: 0;">
                <Spinner ref="Spinner"></Spinner>
            </div>
            <div class="row">
              <div class="col-xxl-4 col-sm-12 col-md-4 col-12 mt-5">
                <div class="visited_pages">
                  <div class="heading">
                    <h3 class="font_size_16 mb_20 most_visited_pages sml_fnt">Most Visited Pages</h3>
                  </div>
                 
                 <div v-show="showViewedContent">
                  <div v-if="most_visited_pages.length" class="add_scroll cust_scroll">
                    <div v-for="(mvp, indx) in most_visited_pages" :key="indx" class="d-flex justify-content-between pages">
                      <p v-tooltip="mvp.name" class="font_size_14 most_visited_pages show_data">{{mvp.name}}</p>
                      <p class="font_size_14 font_bold most_visited_pages visited_numeric">({{mvp.value}})</p>
                    </div>
                  </div>
                  <div v-else class="bar_graph mb_34  white_card border_radius_20 view_no_data">
                    <h2 class="no_data">No Data Found</h2>
                  </div>
                  </div>

                </div>
              </div>
              <div class="col-xxl-4 col-sm-12 col-md-4 col-12 mt-5">
                <div class="visited_pages">
                  <div class="heading">
                    <h3 class="font_size_16 mb_20 most_visited_pages sml_fnt">Most Viewed Content</h3>
                  </div>
                  <div v-show="showViewedContent">
                  <div v-if="most_viewed_content.length" class="add_scroll cust_scroll">
                    <div v-for="(mvc, indx) in most_viewed_content" :key="indx" class="d-flex justify-content-between pages">
                      <p v-tooltip="mvc.name" class="font_size_14 most_visited_pages show_data">{{mvc.name}}</p>
                      <p class="font_size_14 font_bold most_visited_pages visited_numeric">({{mvc.value}})</p>
                    </div>
                  </div>
                  <div v-else class="bar_graph mb_34 white_card border_radius_20 view_no_data">
                    <h2 class="no_data">No Data Found</h2>
                  </div>
                  </div>
                </div>
              </div>
              <div class="col-xxl-4 col-sm-12 col-md-4 col-12 mt-5">
                <div class="visited_pages">
                  <div class="heading">
                    <h3 class="font_size_16 mb_20 most_visited_pages sml_fnt">Most Viewed Video</h3>
                  </div>
                  <div v-show="showViewedContent">
                  <!-- <div class="d-flex justify-content-between pages">
                    <p v-tooltip="'Work From Office'"
                      class="font_size_14 most_visited_pages show_data">Work From Office</p>
                    <p class="font_size_14 font_bold most_visited_pages visited_numeric">(46)</p>
                  </div>
                  <div class="d-flex justify-content-between pages">
                    <p v-tooltip="'Work From Home'"
                      class="font_size_14 most_visited_pages show_data">Work From Home</p>
                    <p class="font_size_14 font_bold most_visited_pages visited_numeric">(46)</p>
                  </div>
                  <div class="d-flex justify-content-between pages">
                    <p v-tooltip="'Productivity'" class="font_size_14 most_visited_pages show_data">
                      Productivity
                    </p>
                    <p class="font_size_14 font_bold most_visited_pages visited_numeric">(46)</p>
                  </div>
                  <div class="d-flex justify-content-between pages">
                    <p v-tooltip="'Wellness'" class="font_size_14 most_visited_pages show_data">
                      Wellness
                    </p>
                    <p class="font_size_14 font_bold most_visited_pages visited_numeric">(46)</p>
                  </div>
                  <div class="d-flex justify-content-between pages">
                    <p v-tooltip="'Equipment'" class="font_size_14 most_visited_pages show_data">
                      Equipment
                    </p>
                    <p class="font_size_14 font_bold most_visited_pages visited_numeric">(46)</p>
                  </div>
                  <div class="d-flex justify-content-between pages">
                    <p v-tooltip="'The Basics'" class="font_size_14 most_visited_pages show_data">
                      The Basics
                    </p>
                    <p class="font_size_14 font_bold most_visited_pages visited_numeric">(46)</p>
                  </div>
                  <div class="d-flex justify-content-between pages">
                    <p v-tooltip="'Equipment'" class="font_size_14 most_visited_pages show_data">
                      Equipment
                    </p>
                    <p class="font_size_14 font_bold most_visited_pages visited_numeric">(46)</p>
                  </div> -->
                  <div v-if="most_viewed_videos.length" class="add_scroll cust_scroll">
                    <div v-for="(mvc, indx) in most_viewed_videos" :key="indx" class="d-flex justify-content-between pages">
                      <p v-tooltip="mvc.name" class="font_size_14 most_visited_pages show_data">{{mvc.name}}</p>
                      <p class="font_size_14 font_bold most_visited_pages visited_numeric">({{mvc.playCount}})</p>
                    </div>
                  </div>
                  <div v-else class="bar_graph mb_34 white_card border_radius_20 view_no_data">
                    <h2 class="no_data">No Data Found</h2>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-6 col-12 mt-5" ref="TotalActivityByRegionDiv">
            <div class="activity_region by_client i_by_client">
              <div class="row">
                <div class=" col-12 col-sm-12 col-lg-7 col-xxl-8">
                  <h2 class="mb_20 total_activity_color">Total Activity</h2>
                </div>
                <div class="d-none">
                  <div class="visited_pages">
                    <div class="heading">
                      <div class="form-group dropdown_client me-left_client">
                        <v-select @input="filterActivityRegion"
                          :reduce="(region_list) => region_list.id" :options="region_list"
                          label="name" placeholder="By Region" name="Region"
                          v-model="listConfig.region_regionBy" v-validate="'required'" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-10 col-sm-10 col-lg-4 col-xxl-3">
                  <div class="visited_pages" data-html2canvas-ignore="true">
                    <div class="heading">
                      <div class="form-group dropdown_client drop_width">
                        <v-select @input="filterActivityRegion"
                          :reduce="(client_list) => client_list.id" :options="client_list"
                          label="name" placeholder="All Clients" name="Client"
                          v-model="listConfig.region_client" v-validate="'required'" />
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="hideActivityRegionDownloadBtn" class="col-2 col-sm-2 col-lg-1 col-xxl-1 text-end">
                  <div data-html2canvas-ignore="true">
                    <a href="javascript:" data-bs-toggle="modal" data-bs-target="#download" @click="prepareChart('TotalActivityByRegionDiv', 'Total-Activity', '600', '276', 'TotalActivityByRegionHeight', activity_region)">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                        viewBox="0 0 16 16">
                        <g data-name="Group 12689">
                          <path data-name="Path 114"
                            d="M4 14.5v.875A2.625 2.625 0 0 0 6.625 18h8.75A2.625 2.625 0 0 0 18 15.375V14.5M14.5 11 11 14.5m0 0L7.5 11m3.5 3.5V4"
                            transform="translate(-3 -3)"
                            style="fill:none;stroke:#a9a9a9;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px" />
                        </g>
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="mapping TotalActivityByRegionHeight">
              <div class="row">
                <div class="col-md-7 text-center">
                  <div v-if="activity_region.length" class="pieIchart">
                    <pie-chart :height="350" :chart-data="piechartdatacollection"
                      ref="appendPieChart"></pie-chart>
                  </div>
                </div>
                <div class="col-md-5 align-self-center">
                  <div class="row mb_32">
                    <div class="col-12 text-end">
                      <div v-if="activity_region.length" class="tab_report toggle_switch min_max_btn" data-html2canvas-ignore="true">
                        <ul class="nav nav-tabs" id="myTabRegion" role="tablist">
                          <li class="nav-item" role="presentation">
                            <button class="nav-link active" id="percent-tabRegion"
                              data-bs-toggle="tab" data-bs-target="#percentRegion"
                              type="button" role="tab" aria-controls="percent"
                              aria-selected="true">%</button>
                          </li>
                          <li class="nav-item" role="presentation">
                            <button class="nav-link" id="hash-tabRegion"
                              data-bs-toggle="tab" data-bs-target="#hashRegion"
                              type="button" role="tab" aria-controls="hash"
                              aria-selected="false">#</button>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="d-none col-0 text-end">
                      <div class="d-flex justify-content-end ">
                        <div>
                          <a href="javascript:" >
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                              viewBox="0 0 16 16">
                              <g data-name="Group 12689">
                                <path data-name="Path 114"
                                  d="M4 14.5v.875A2.625 2.625 0 0 0 6.625 18h8.75A2.625 2.625 0 0 0 18 15.375V14.5M14.5 11 11 14.5m0 0L7.5 11m3.5 3.5V4"
                                  transform="translate(-3 -3)"
                                  style="fill:none;stroke:#a9a9a9;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px" />
                              </g>
                            </svg>
                          </a>
                        </div>
                        <div class="dropdown calender_dropdown">
                          <a href="javascript:void(0)" id="dropdownMenuLink1"
                            data-bs-toggle="dropdown" aria-expanded="false"
                            class="ml_20 dropdown-toggle" role="button"
                            data-bs-auto-close="false">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                              viewBox="0 0 16 16">
                              <path data-name="Path 130"
                                d="M6.889 6.111V3m6.222 3.111V3m-7 6.222h7.778M4.556 17h10.888A1.556 1.556 0 0 0 17 15.444V6.111a1.556 1.556 0 0 0-1.556-1.556H4.556A1.556 1.556 0 0 0 3 6.111v9.333A1.556 1.556 0 0 0 4.556 17z"
                                transform="translate(-2 -2)"
                                style="fill:none;stroke:#a9a9a9;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px" />
                            </svg>
                          </a>
                          <div class="dropdown-menu px_20 py_20 calender_date for_filter"
                            aria-labelledby="dropdownMenuLink1">
                            <div class="date pl_6">
                              <div
                                class="d-flex justify-content-between align-items-center">
                                <div class="heading_5 font_bold mb_10">From</div>
                                <a href="javascript:" class="reset_btn"
                                  @click="resetDatesRegion">
                                  <svg xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                    version="1.1" x="0px" y="0px"
                                    viewBox="0 0 1000 1000"
                                    enable-background="new 0 0 1000 1000"
                                    xml:space="preserve">
                                    <metadata> Svg Vector Icons :
                                      http://www.onlinewebfonts.com/icon
                                    </metadata>
                                    <g>
                                      <path
                                        d="M829.7,134.7C740.5,59,624.7,10,500,10C228.3,10,10,228.3,10,500h89.1C99.1,277.3,277.3,99.1,500,99.1c102.5,0,196,35.7,262.8,98l-75.7,71.3l267.3,49L914.3,50.1L829.7,134.7z M179.3,869.7C268.3,945.5,379.7,990,500,990c271.7,0,490-218.3,490-490h-89.1c0,222.7-178.2,400.9-400.9,400.9c-98,0-187.1-35.7-253.9-89.1l84.7-80.2l-267.3-49l40.1,267.3L179.3,869.7z" />
                                    </g>
                                  </svg>
                                </a>
                              </div>
                              <div
                                class="text_black font_size_14 border px_12 py_10 d-flex align-items-center">
                                <span class="mr_10">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="16"
                                    height="16" viewBox="0 0 16 16">
                                    <path data-name="Path 130"
                                      d="M6.889 6.111V3m6.222 3.111V3m-7 6.222h7.778M4.556 17h10.888A1.556 1.556 0 0 0 17 15.444V6.111a1.556 1.556 0 0 0-1.556-1.556H4.556A1.556 1.556 0 0 0 3 6.111v9.333A1.556 1.556 0 0 0 4.556 17z"
                                      transform="translate(-2 -2)"
                                      style="fill:none;stroke:#a9a9a9;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px" />
                                  </svg>
                                </span>
                                <datepicker placeholder="MM DD, YYYY"
                                  v-model="listConfig.region_sdate"
                                  :format="customFormatter"
                                  @selected="changeStartDateRegion"
                                  :disabledDates="disabledFutureDates"></datepicker>
                                <p class="min-date-value d-none">
                                  {{ listConfig.region_sdate | formatDate }}
                                </p>
                              </div>
                            </div>
                            <div class="date pl_6 mt_20">
                              <div class="heading_5 font_bold mb_10">To</div>
                              <div
                                class="text_black font_size_14 border px_12 py_10 d-flex align-items-center">
                                <span class="mr_10">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="16"
                                    height="16" viewBox="0 0 16 16">
                                    <path data-name="Path 130"
                                      d="M6.889 6.111V3m6.222 3.111V3m-7 6.222h7.778M4.556 17h10.888A1.556 1.556 0 0 0 17 15.444V6.111a1.556 1.556 0 0 0-1.556-1.556H4.556A1.556 1.556 0 0 0 3 6.111v9.333A1.556 1.556 0 0 0 4.556 17z"
                                      transform="translate(-2 -2)"
                                      style="fill:none;stroke:#a9a9a9;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px" />
                                  </svg>
                                </span>
                                <datepicker placeholder="MM DD, YYYY"
                                  v-model="listConfig.region_edate"
                                  :format="customFormatter"
                                  @selected="changeEndDateRegion"
                                  :disabled="isDisabledRegion"
                                  :disabledDates="disabledDates"></datepicker>
                                <p class="max-date-value d-none">
                                  {{ listConfig.region_edate | formatDate }}
                                </p>
                              </div>
                            </div>
                            <div class="button mt_30">
                              <a href="javascript:" @click="updateActivityRegion"
                                class="btn btn_primary px_14">Update</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-if="activity_region.length" class="tab-content" id="myTabContent">
                    <div class="tab-pane fade show active" id="percentRegion" role="tabpanel"
                      aria-labelledby="percent-tabRegion">
                      <div class="registrant cust_scroll">
                        <div v-for="(acr, inx) in activity_region" :key="inx"
                          class="d-flex justify-content-between registrant_section graph_details">
                          <div class="name">
                            <h3 class="graph_points i_point">
                              <span v-if="inx == 0" class="circle blue"></span>
                              <span v-if="inx == 1" class="circle pink"></span>
                              <span v-if="inx == 2" class="circle orange"></span>
                              <span v-if="inx == 3" class="circle skyblue"></span>
                              <span v-if="inx == 4" class="circle green"></span>
                              {{ acr.name }}
                            </h3>
                          </div>
                          <div class="percent">
                            <h3 class="graph_points i_point">{{ acr.percent }}%</h3>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="tab-pane fade" id="hashRegion" role="tabpanel"
                      aria-labelledby="hash-tabRegion">
                      <div class="registrant cust_scroll">
                        <div v-for="(acr, inx) in activity_region" :key="inx"
                          class="d-flex justify-content-between registrant_section graph_details">
                          <div class="name">
                            <h3 class="graph_points i_point">
                              <span v-if="inx == 0" class="circle blue"></span>
                              <span v-if="inx == 1" class="circle pink"></span>
                              <span v-if="inx == 2" class="circle orange"></span>
                              <span v-if="inx == 3" class="circle skyblue"></span>
                              <span v-if="inx == 4" class="circle green"></span>
                              {{ acr.name }}
                            </h3>
                          </div>
                          <div class="percent">
                            <h3 class="graph_points i_point">{{ acr.value }}</h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="!activity_region.length" class="col-md-12 text-center">
                  <div class="align-items-start d-flex w-100 withprogress">
                    <div class="detailsProgress justify-content-between w-100">
                      <div
                        class="align-items-center d-flex d-xxl-flex justify-content-between w-100 white_card border_radius_20 h_250 font_drop mg_min">
                        <h2 class="no_data">No Data Found</h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="self_report mt-0">
        <div class="row">
          <div class="col-md-12 col-xl-6 col-xxl-6 for-notepad mt-5 mt-lg-5" ref="ClientActivityDiv">
            <div
              class=" px-0 mb_30 registrtans_site_main client_group_btn  white_card border-radius-20 h-100">
              <div
                class="activity_region region_cards px_20 pb_14 pad_bottom_non registrtans_site activity_region site_n_client">
                <div class="row align-items-center">
                  <div class="col-8 col-sm-6">
                    <h2 class="mb_20 client_card_head">Client Activity</h2>
                  </div>
                  <div class="col-4 col-sm-6">
                    <div
                      class="d-flex  justify-content-end align-items-baseline">
                      <div class="d-none visited_pages mr_12">
                        <div class="heading">
                          <div class="form-group by_min">
                            <v-select :reduce="(region_list) => region_list.id"
                              :options="region_list" label="name" placeholder="By Region"
                              name="Region" v-model="listConfig.clients_regionBY"
                              v-validate="'required'" />
                          </div>
                        </div>
                      </div>
                      <div v-if="hideClientActivityDownloadBtn" class="align-items-center d-flex justify-content-end">
                        <div class="ml_24" data-html2canvas-ignore="true">
                          <a href="javascript:" data-bs-toggle="modal" data-bs-target="#download" @click="prepareChart('ClientActivityDiv', clientActivityTabName, '', '', '', clientActivityTabData)">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                              viewBox="0 0 16 16">
                              <g data-name="Group 12689">
                                <path data-name="Path 114"
                                  d="M4 14.5v.875A2.625 2.625 0 0 0 6.625 18h8.75A2.625 2.625 0 0 0 18 15.375V14.5M14.5 11 11 14.5m0 0L7.5 11m3.5 3.5V4"
                                  transform="translate(-3 -3)"
                                  style="fill:none;stroke:#a9a9a9;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px" />
                              </g>
                            </svg>
                          </a>
                        </div>
                        <div class="d-none dropdown calender_dropdown">
                          <a href="javascript:void(0)" id="dropdownMenuLink2"
                            data-bs-toggle="dropdown" aria-expanded="false"
                            class="ml_20 dropdown-toggle" role="button"
                            data-bs-auto-close="false">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                              viewBox="0 0 16 16">
                              <path data-name="Path 130"
                                d="M6.889 6.111V3m6.222 3.111V3m-7 6.222h7.778M4.556 17h10.888A1.556 1.556 0 0 0 17 15.444V6.111a1.556 1.556 0 0 0-1.556-1.556H4.556A1.556 1.556 0 0 0 3 6.111v9.333A1.556 1.556 0 0 0 4.556 17z"
                                transform="translate(-2 -2)"
                                style="fill:none;stroke:#a9a9a9;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px" />
                            </svg>
                          </a>
                          <div class="dropdown-menu px_20 py_20 calender_date for_filter"
                            aria-labelledby="dropdownMenuLink2">
                            <div class="date pl_6">
                              <div
                                class="d-flex justify-content-between align-items-center">
                                <div class="heading_5 font_bold mb_10">From</div>
                                <a href="javascript:void(0)" class="reset_btn"
                                  @click="resetDatesClients">
                                  <svg xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                    version="1.1" x="0px" y="0px"
                                    viewBox="0 0 1000 1000"
                                    enable-background="new 0 0 1000 1000"
                                    xml:space="preserve">
                                    <metadata> Svg Vector Icons :
                                      http://www.onlinewebfonts.com/icon
                                    </metadata>
                                    <g>
                                      <path
                                        d="M829.7,134.7C740.5,59,624.7,10,500,10C228.3,10,10,228.3,10,500h89.1C99.1,277.3,277.3,99.1,500,99.1c102.5,0,196,35.7,262.8,98l-75.7,71.3l267.3,49L914.3,50.1L829.7,134.7z M179.3,869.7C268.3,945.5,379.7,990,500,990c271.7,0,490-218.3,490-490h-89.1c0,222.7-178.2,400.9-400.9,400.9c-98,0-187.1-35.7-253.9-89.1l84.7-80.2l-267.3-49l40.1,267.3L179.3,869.7z" />
                                    </g>
                                  </svg>
                                </a>
                              </div>
                              <div
                                class="text_black font_size_14 border px_12 py_10 d-flex align-items-center">
                                <span class="mr_10">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="16"
                                    height="16" viewBox="0 0 16 16">
                                    <path data-name="Path 130"
                                      d="M6.889 6.111V3m6.222 3.111V3m-7 6.222h7.778M4.556 17h10.888A1.556 1.556 0 0 0 17 15.444V6.111a1.556 1.556 0 0 0-1.556-1.556H4.556A1.556 1.556 0 0 0 3 6.111v9.333A1.556 1.556 0 0 0 4.556 17z"
                                      transform="translate(-2 -2)"
                                      style="fill:none;stroke:#a9a9a9;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px" />
                                  </svg>
                                </span>
                                <datepicker placeholder="MM DD, YYYY"
                                  v-model="listConfig.clients_sdate"
                                  :format="customFormatter"
                                  @selected="changeStartDateClients"
                                  :disabledDates="disabledFutureDates"></datepicker>
                                <p class="min-date-value d-none">
                                  {{ listConfig.clients_sdate | formatDate }}
                                </p>
                              </div>
                            </div>
                            <div class="date pl_6 mt_20">
                              <div class="heading_5 font_bold mb_10">To</div>
                              <div
                                class="text_black font_size_14 border px_12 py_10 d-flex align-items-center">
                                <span class="mr_10">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="16"
                                    height="16" viewBox="0 0 16 16">
                                    <path data-name="Path 130"
                                      d="M6.889 6.111V3m6.222 3.111V3m-7 6.222h7.778M4.556 17h10.888A1.556 1.556 0 0 0 17 15.444V6.111a1.556 1.556 0 0 0-1.556-1.556H4.556A1.556 1.556 0 0 0 3 6.111v9.333A1.556 1.556 0 0 0 4.556 17z"
                                      transform="translate(-2 -2)"
                                      style="fill:none;stroke:#a9a9a9;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px" />
                                  </svg>
                                </span>
                                <datepicker placeholder="MM DD, YYYY"
                                  v-model="listConfig.clients_edate"
                                  :format="customFormatter"
                                  @selected="changeEndDateClients"
                                  :disabled="isDisabledClients"
                                  :disabledDates="disabledDates"></datepicker>
                                <p class="max-date-value d-none">
                                  {{ listConfig.clients_edate | formatDate }}
                                </p>
                              </div>
                            </div>
                            <div class="button mt_30">
                              <a href="javascript:" @click="updateClientsActivity"
                                class="btn btn_primary px_14">Update</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="tab_report px_20 py_20 py-2 py-md-4 text-center text-xl-start">
                <ul class="nav nav-tabs mb_16 group_btn mt-2" id="myTabClients" role="tablist">
                  <li class="nav-item" role="presentation">
                    <button class="nav-link four_btn active" @click="setClientActivityData('Client-Activity-Registrants', registrants_data)" id="registrants-tab" data-bs-toggle="tab" data-bs-target="#registrants" type="button" role="tab" aria-controls="home" aria-selected="true">Registrants</button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button class="nav-link four_btn" @click="setClientActivityData('Client-Activity-SA-Activity', saActivity_data)" id="saActivity-tab" data-bs-toggle="tab" data-bs-target="#saActivity" type="button" role="tab" aria-controls="profile" aria-selected="false">SA Activity</button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button class="nav-link four_btn" @click="setClientActivityData('Client-Activity-Eval-Reports', evalReports_data)" id="evalReports-tab" data-bs-toggle="tab" data-bs-target="#evalReports" type="button" role="tab" aria-controls="contact" aria-selected="false">Eval Reports</button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button class="nav-link four_btn" @click="setClientActivityData('Client-Activity-Ergo-Eval', ergoEval_data)" id="ergoEval-tab" data-bs-toggle="tab" data-bs-target="#ergoEval" type="button" role="tab" aria-controls="Eval" aria-selected="false">Ergo Eval</button>
                  </li>
                </ul>
                <div class="tab-content" id="myTabClientsContent">
                  <div class="tab-pane fade show active" id="registrants" role="tabpanel"
                    aria-labelledby="registrants-tab">
                    <div v-if="registrants_data.length" class="graph scroll-chart cust_scroll">
                      <bar-chart :width="600" :height="300" ref="appendRegistrants" 
                      :chart-data="registrantsdatacollection">
                      </bar-chart>
                    </div>
                    <div v-else class="bar_graph mb_34 font_drop">
                      <h2 class="no_data">No Data Found</h2>
                    </div>
                  </div>
                  <div class="tab-pane fade" id="saActivity" role="tabpanel"
                    aria-labelledby="saActivity-tab">
                    <div v-if="saActivity_data.length" class="graph scroll-chart cust_scroll">
                      <bar-chart :width="600" :height="300" ref="appendsaActivity" 
                      :chart-data="saActivitydatacollection">
                      </bar-chart>
                    </div>
                    <div v-else class="bar_graph mb_34 font_drop">
                      <h2 class="no_data">No Data Found</h2>
                    </div>
                  </div>
                  <div class="tab-pane fade" id="evalReports" role="tabpanel"
                    aria-labelledby="evalReports-tab">
                    <div v-if="evalReports_data.length" class="graph scroll-chart cust_scroll">
                      <bar-chart :width="600" :height="300" ref="appendevalReports" 
                      :chart-data="evalReportsdatacollection">
                      </bar-chart>
                    </div>
                    <div v-else class="bar_graph mb_34 font_drop">
                      <h2 class="no_data">No Data Found</h2>
                    </div>
                  </div>
                  <div class="tab-pane fade" id="ergoEval" role="tabpanel" aria-labelledby="ergoEval-tab">
                    <div v-if="ergoEval_data.length" class="graph scroll-chart cust_scroll">
                      <bar-chart :width="600" :height="300" ref="appendergoEval" 
                      :chart-data="ergoEvaldatacollection">
                      </bar-chart>
                    </div>
                    <div v-else class="bar_graph mb_34 font_drop">
                      <h2 class="no_data">No Data Found</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12 col-xl-6 col-xxl-6 for-notepad mt-5 mt-lg-5" ref="SiteRegistrantsDiv">
            <div class="registrtans_site_main  white_card border-radius-20 h-100">
              <div class=" pad_bottom_non registrtans_site activity_region site_n_client">
                <div class="row align-items-center">
                  <div class="col-8 col-sm-6">
                    <h2 class="mb_20 client_card_head">Site Registrants</h2>
                  </div>
                  <div class="col-4 col-sm-6">
                    <div
                      class="d-flex justify-content-end align-items-baseline">
                      <div class="d-none visited_pages mr_12">
                        <div class="heading">
                          <div class="form-group by_min">
                            <v-select @input="filterSiteRegistrants"
                              :reduce="(region_list) => region_list.id"
                              :options="region_list" label="name" placeholder="By Region"
                              name="Region" v-model="listConfig.site_regionBy"
                              v-validate="'required'" />
                          </div>
                        </div>
                      </div>
                      <div v-if="hideSiteRegistrantsDownloadBtn" class="align-items-center d-flex justify-content-end">
                        <div class="ml_24" data-html2canvas-ignore="true">
                          <a v-if="RegistrantsUnique" href="javascript:" data-bs-toggle="modal" data-bs-target="#download" @click="prepareChart('SiteRegistrantsDiv', 'Current-Site-Registrants', '', '', '', site_registrantsUnique)">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                              <g data-name="Group 12689">
                                <path data-name="Path 114"
                                  d="M4 14.5v.875A2.625 2.625 0 0 0 6.625 18h8.75A2.625 2.625 0 0 0 18 15.375V14.5M14.5 11 11 14.5m0 0L7.5 11m3.5 3.5V4"
                                  transform="translate(-3 -3)"
                                  style="fill:none;stroke:#a9a9a9;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px" />
                              </g>
                            </svg>
                          </a>
                          <a v-if="RegistrantsRevisits" href="javascript:" data-bs-toggle="modal" data-bs-target="#download" @click="prepareChart('SiteRegistrantsDiv', 'YTD-Site-Registrants', '', '', '', site_registrantsRevisits)">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                              <g data-name="Group 12689">
                                <path data-name="Path 114"
                                  d="M4 14.5v.875A2.625 2.625 0 0 0 6.625 18h8.75A2.625 2.625 0 0 0 18 15.375V14.5M14.5 11 11 14.5m0 0L7.5 11m3.5 3.5V4"
                                  transform="translate(-3 -3)"
                                  style="fill:none;stroke:#a9a9a9;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px" />
                              </g>
                            </svg>
                          </a>
                        </div>
                        <div class="d-none dropdown calender_dropdown">
                          <a href="javascript:void(0)" id="dropdownMenuLink3"
                            data-bs-toggle="dropdown" aria-expanded="false"
                            class="ml_20 dropdown-toggle" role="button"
                            data-bs-auto-close="false">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                              viewBox="0 0 16 16">
                              <path data-name="Path 130"
                                d="M6.889 6.111V3m6.222 3.111V3m-7 6.222h7.778M4.556 17h10.888A1.556 1.556 0 0 0 17 15.444V6.111a1.556 1.556 0 0 0-1.556-1.556H4.556A1.556 1.556 0 0 0 3 6.111v9.333A1.556 1.556 0 0 0 4.556 17z"
                                transform="translate(-2 -2)"
                                style="fill:none;stroke:#a9a9a9;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px" />
                            </svg>
                          </a>
                          <div class="dropdown-menu px_20 py_20 calender_date for_filter"
                            aria-labelledby="dropdownMenuLink3">
                            <div class="date pl_6">
                              <div
                                class="d-flex justify-content-between align-items-center">
                                <div class="heading_5 font_bold mb_10">From</div>
                                <a href="javascript:void(0)" class="reset_btn"
                                  @click="resetDatesSite">
                                  <svg xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                    version="1.1" x="0px" y="0px"
                                    viewBox="0 0 1000 1000"
                                    enable-background="new 0 0 1000 1000"
                                    xml:space="preserve">
                                    <metadata> Svg Vector Icons :
                                      http://www.onlinewebfonts.com/icon
                                    </metadata>
                                    <g>
                                      <path
                                        d="M829.7,134.7C740.5,59,624.7,10,500,10C228.3,10,10,228.3,10,500h89.1C99.1,277.3,277.3,99.1,500,99.1c102.5,0,196,35.7,262.8,98l-75.7,71.3l267.3,49L914.3,50.1L829.7,134.7z M179.3,869.7C268.3,945.5,379.7,990,500,990c271.7,0,490-218.3,490-490h-89.1c0,222.7-178.2,400.9-400.9,400.9c-98,0-187.1-35.7-253.9-89.1l84.7-80.2l-267.3-49l40.1,267.3L179.3,869.7z" />
                                    </g>
                                  </svg>
                                </a>
                              </div>
                              <div
                                class="text_black font_size_14 border px_12 py_10 d-flex align-items-center">
                                <span class="mr_10">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="16"
                                    height="16" viewBox="0 0 16 16">
                                    <path data-name="Path 130"
                                      d="M6.889 6.111V3m6.222 3.111V3m-7 6.222h7.778M4.556 17h10.888A1.556 1.556 0 0 0 17 15.444V6.111a1.556 1.556 0 0 0-1.556-1.556H4.556A1.556 1.556 0 0 0 3 6.111v9.333A1.556 1.556 0 0 0 4.556 17z"
                                      transform="translate(-2 -2)"
                                      style="fill:none;stroke:#a9a9a9;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px" />
                                  </svg>
                                </span>
                                <datepicker placeholder="MM DD, YYYY"
                                  v-model="listConfig.site_sdate"
                                  :format="customFormatter"
                                  @selected="changeStartDateSite"
                                  :disabledDates="disabledFutureDates"></datepicker>
                                <p class="min-date-value d-none">
                                  {{ listConfig.site_sdate | formatDate }}
                                </p>
                              </div>
                            </div>
                            <div class="date pl_6 mt_20">
                              <div class="heading_5 font_bold mb_10">To</div>
                              <div
                                class="text_black font_size_14 border px_12 py_10 d-flex align-items-center">
                                <span class="mr_10">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="16"
                                    height="16" viewBox="0 0 16 16">
                                    <path data-name="Path 130"
                                      d="M6.889 6.111V3m6.222 3.111V3m-7 6.222h7.778M4.556 17h10.888A1.556 1.556 0 0 0 17 15.444V6.111a1.556 1.556 0 0 0-1.556-1.556H4.556A1.556 1.556 0 0 0 3 6.111v9.333A1.556 1.556 0 0 0 4.556 17z"
                                      transform="translate(-2 -2)"
                                      style="fill:none;stroke:#a9a9a9;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px" />
                                  </svg>
                                </span>
                                <datepicker placeholder="MM DD, YYYY"
                                  v-model="listConfig.site_edate"
                                  :format="customFormatter"
                                  @selected="changeEndDateSite"
                                  :disabled="isDisabledSite"
                                  :disabledDates="disabledDates"></datepicker>
                                <p class="max-date-value d-none">
                                  {{ listConfig.site_edate | formatDate }}
                                </p>
                              </div>
                            </div>
                            <div class="button mt_30">
                              <a href="javascript:" @click="updateSiteRegistrants"
                                class="btn btn_primary px_14">Update</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="p-2 p-lg-4 pick-graph">
                <div class="d-lg-flex align-items-center justify-content-between mb-3">
                  <div class="align-items-center d-flex justify-content-lg-between justify-content-center">
                    <img :src="require('@/assets/images/resistant.svg')">
                    <h3 v-if="RegistrantsUnique" class="bold_user">Current Users</h3>
                    <h3 v-if="RegistrantsRevisits" class="bold_user">YTD (year to date)</h3>
                    <h4 class="mini_margin">
                        <span v-if="RegistrantsUnique"> {{site_registrants_unique}} </span> 
                        <span v-if="RegistrantsRevisits"> {{site_registrants_revisits}} </span> 
                        <img :src="require('@/assets/images/up_arrow.svg')" class="img-arrow">
                    </h4>
                  </div>
                  <div class="my-md-2 my-0">
                    <div class="tab_report self-assessment_point unique_client_btn toggle_switch my-3 my-lg-0 text-center">
                      <ul id="myTabSite" role="tablist" class="nav nav-tabs self_btn_list">
                        <li role="presentation" @click="showRegistrantsUnique(), downloadSiteRegistrantsBtn(site_registrantsUnique)" class="nav-item">
                          <button id="percent-tabSite" data-bs-toggle="tab" data-bs-target="#percentSite" type="button" role="tab" aria-controls="percent" aria-selected="true" class="nav-link self_btn_f unique_btn">Current</button>
                        </li>
                        <li role="presentation" @click="showRegistrantsRevisits(), downloadSiteRegistrantsBtn(site_registrantsRevisits)" class="nav-item">
                          <button id="hash-tabSite" data-bs-toggle="tab" data-bs-target="#hashSite" type="button" role="tab" aria-controls="hash" aria-selected="false" class="nav-link self_btn_f unique_btn active">YTD</button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="tab-content" id="myTabSiteContent">
                  <div class="tab-pane fade" id="percentSite" role="tabpanel" aria-labelledby="percent-tabSite">
                    <div v-if="site_registrantsUnique.length" class="line_chart">
                      <line-chart :height='225' :chart-data="siteUniqueCollection" ref="appendSiteUnique"></line-chart>
                    </div>
                    <div v-else class="line_chart font_drop">
                      <h2 class="no_data">No Data Found</h2>
                    </div>
                  </div>
                  <div class="tab-pane fade show active" id="hashSite" role="tabpanel" aria-labelledby="hash-tabSite">
                    <div v-if="site_registrantsRevisits.length" class="line_chart">
                      <line-chart :height='225' :chart-data="siteRevisitsCollection" ref="appendSiteRevisits"></line-chart>
                    </div>
                    <div v-else class="line_chart font_drop">
                      <h2 class="no_data">No Data Found</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal fade box_up download_modal" id="download" tabindex="-1" aria-labelledby="downloadLabel"
        aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
        <div class="modal-dialog modal-dialog-centered modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="downloadLabel">
                <h3 class="heading_2">Download</h3>
              </h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div class="row justify-content-around">
                <div class="col-md-3">
                  <div class="image_upload text-center downloadDocumentClass disabled">
                    <a href="javascript:" class="text-center" @click="enableDownloadBtn('document')">
                      <svg xmlns="http://www.w3.org/2000/svg" width="36" height="42" viewBox="0 0 36 42" class="d-block">
                        <path data-name="Path 122" d="M13.889 23h13.333m-13.333 8.889h13.333M31.667 43H9.444A4.444 4.444 0 0 1 5 38.556V7.444A4.444 4.444 0 0 1 9.444 3h12.414a2.222 2.222 0 0 1 1.571.651L35.46 15.682a2.222 2.222 0 0 1 .651 1.571v21.3A4.444 4.444 0 0 1 31.667 43z" transform="translate(-4 -2)" style=" fill:none; stroke-linecap:round; stroke-linejoin:round; stroke-width:2px " /> </svg>
                      Download as a document
                    </a>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="image_upload text-center downloadImageClass">
                    <a href="javascript:" class="text-center" @click="enableDownloadBtn('image')">
                      <svg xmlns="http://www.w3.org/2000/svg" width="38.891" height="38.063" viewBox="0 0 38.891 38.063" class="d-block">
                        <path data-name="Path 143" d="m4 31.047 10.336-10.336a4.508 4.508 0 0 1 6.374 0l10.337 10.336m-4.508-4.508 3.575-3.575a4.508 4.508 0 0 1 6.374 0l3.575 3.575M26.539 13.016h.023M8.508 40.063h27.047a4.508 4.508 0 0 0 4.508-4.508V8.508A4.508 4.508 0 0 0 35.555 4H8.508A4.508 4.508 0 0 0 4 8.508v27.047a4.508 4.508 0 0 0 4.508 4.508z" transform="translate(-2.586 -3)" style=" fill: none; stroke-linecap: round; stroke-linejoin: round; stroke-width: 2px; " />
                      </svg>
                      Download as an image
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer border-top-0 mx-auto mb_50">
              <button type="button" class="btn btn_primary_light mr_30 no_mr_30" data-bs-dismiss="modal"> Close </button>
              <button type="button" class="btn btn_primary sml_download" @click="downloadChart()">Download</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import moment from "moment";
import "vue-select/dist/vue-select.css";
import Datepicker from "vuejs-datepicker";
import PieChart from "../../components/charts/PieChart.js";
import BarChart from "../../components/charts/BarChart.js";
import LineChart from "../../components/charts/LineChart.js";
// import jsPDF from 'jspdf';
import html2Canvas from 'html2canvas';
import Spinner from "../../components/Spinner.vue";
import { utils, writeFileXLSX } from 'xlsx';
import commonFunction  from '../../mixin/commonFunction';

export default {
  name: "client_activity",
  mixins: [commonFunction],
  components: {
    Datepicker,
    PieChart,
    BarChart,
    LineChart,
    Spinner
  },
  data() {
    return {
      showViewedContent:false,
      show: false,
      chart_options: {
        legend: { display: false },
        cornerRadius: 7,
      },
      barChartOptions : {
          legend: { display: false },
          responsive:window.innerWidth < 767 ? false : true,
          cornerRadius: 7,
      },
      piechartdatacollection: {
        labels: [],
        datasets: [{
          backgroundColor: ['#6665DD', '#FF6A81', '#F2A149', '#4880FF', '#29CB96'],
          data: []
        }]
      },
      siteUniqueCollection: {
        labels: [],
        datasets: [{
          data: [],
          label: "Current Site Registrants",
          borderColor: "#4880FF",
          fill: false,
        }, ],
      },
      siteRevisitsCollection: {
        labels: [],
        datasets: [{
          data: [],
          label: "YTD Site Registrants",
          borderColor: "#29CB96",
          fill: false,
        }, ],
      },
      registrantsdatacollection: {
        labels: [],
        datasets: [{
          data: [],
          label: "Registrants",
          backgroundColor: [],
          maxBarThickness: 40,
        }]
      },
      saActivitydatacollection: {
        labels: [],
        datasets: [{
          data: [],
          label: "SA Activity",
          backgroundColor: [],
          maxBarThickness: 40,
        }]
      },
      evalReportsdatacollection: {
        labels: [],
        datasets: [{
          data: [],
          label: "Eval Reports",
          backgroundColor: [],
          maxBarThickness: 40,
        }]
      },
      ergoEvaldatacollection: {
        labels: [],
        datasets: [{
          data: [],
          label: "Ergo Eval",
          backgroundColor: [],
          maxBarThickness: 40,
        }]
      },
      hideClientActivityDownloadBtn: true,
      client_activity_registrantsCSV: [],
      registrants_data: [],
      client_activity_saActivityCSV: [],
      saActivity_data: [],
      client_activity_evalReportsCSV: [],
      evalReports_data: [],
      client_activity_ergoEvalCSV: [],
      ergoEval_data: [],
      client_list: [],
      region_list: [],
      disabledDates: {
        to: new Date(Date.now() - 8640000),
      },
      disabledFutureDates: {
        from: new Date(Date.now()),
      },
      listConfig: {
        offset: (this.currentPage - 1) * this.itemsPerPage,
        limit: this.itemsPerPage,
        sortBy: "assessment_date",
        order: "DESC",
        search_string: null,

        typeOfAssessment: '',
        start_date: null,
        end_date: null,
        regionBy: null,
        region_sdate: null,
        region_edate: null,
        region_client: null,
        region_regionBy: null,
        clients_sdate: null,
        clients_edate: null,
        clients_regionBy: null,
        site_sdate: null,
        site_edate: null,
        site_regionBy: null,
      },
      data_length: 0,
      selected: '',
      isDisabled: '',
      isDisabledRegion: '',
      isDisabledClients: '',
      isDisabledSite: '',
      activity_region_registrantsCSV: [],
      activity_region_EECSV: [],
      activity_region_SACSV: [],
      activity_region: [],
      hideActivityRegionDownloadBtn: true,
      RegistrantsUnique: false,
      RegistrantsRevisits: true,
      hideSiteRegistrantsDownloadBtn: true,
      site_registrants_unique: 0,
      site_registrants_revisits: 0,
      site_registrantsUnique: [],
      site_registrantsUniqueCSV: [],
      site_registrantsRevisits: [],
      site_registrantsRevisitsCSV: [],
      most_visited_pages: [],
      most_viewed_content: [],
      clientActivityTabName: 'Client-Activity-Registrants',
      clientActivityTabData: [],

      downloadRef: '',
      downloadType: 'image',
      downloadImageName: '',
      downloadMaxHeight: '',
      downloadMinHeight: '',
      downloadHeightClass: '',
      downloadCSVData: '',
      rowsData: [],
      most_viewed_videos: [],
      previousStartDate: null,
    };
  },
  computed: {
    btnText: function () {
      if (this.show) {
        return "";
      }
      return "";
    },
  },
  destroyed() {
    this.$parent.employeeDashboard = false
  },
  beforeMount() {
    this.clientsForActivityRegion();
  },
  mounted() {
    document.title = "PBErgo - " + this.$router.currentRoute.meta.page_title;
    this.$parent.employeeDashboard = true;
    this.mostVisitedData();
    this.mostViewedData();
    this.filterActivityRegion();
    this.filterClientsActivity();
    this.filterSiteRegistrants();
  },
  methods: {
    mostViewedData() {
      let config = {
        method: "get",
        url: process.env.VUE_APP_API_URL + "/api/most-viewed-video",
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
        },
      };
      this.axios(config)
        .then(({ data }) => {
          var decodedJson = this.decodeAPIResponse(data.data);
          this.most_viewed_videos = decodedJson;
        })
        .catch(({ response }) => {
            this.$toast.error(response.data.error, {
                    position: "top-right",
                    duration: 5000,
                });
          });
    },
    prepareChart(ref, imageName, maxHeight, minHeight, heightClass, csvData) {
      this.downloadRef = ref;
      this.downloadImageName = imageName;
      this.downloadMaxHeight = maxHeight;
      this.downloadMinHeight = minHeight;
      this.downloadHeightClass = heightClass;
      this.downloadCSVData = csvData;
      this.enableDownloadBtn('image');
    },
    enableDownloadBtn(btnType) {
      if(btnType == 'image') {
        $('.downloadImageClass').removeClass('disabled');
        $('.downloadDocumentClass').addClass('disabled');
      }
      else if(btnType == 'document') {
        $('.downloadDocumentClass').removeClass('disabled');
        $('.downloadImageClass').addClass('disabled');
      }
      this.downloadType = btnType;
    },
    setClientActivityData(tabName, tabData) {
      this.clientActivityTabName = tabName;
      this.clientActivityTabData = tabData;
      if(tabData.length == 0) {
        this.hideClientActivityDownloadBtn = false;
      }
      else {
        this.hideClientActivityDownloadBtn = true;
      }
    },
    async downloadChart() {
      var _this = this;
      _this.$parent.$refs.fullpageloader.is_full_page_loader = true;
              //Audit log entry     
        var sectionName = '';
        switch(_this.downloadRef) {
          case 'TotalActivityByRegionDiv':
            sectionName = 'Total Activity';
            break;
          case 'ClientActivityDiv':
            sectionName = 'Client Activity';
            break;
          case 'SiteRegistrantsDiv':
            sectionName = 'Site Registrants';
            break;
        } 
        var requestData = {'section': sectionName,'dashboard':'Client Activity'};
        let config = {
          method: "post",
          url: process.env.VUE_APP_API_URL + "/api/audit-log-for-download",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
          },
          data: this.encodeAPIRequestWithImages(requestData),
        };
        this.axios(config)
        .then(({ data }) => {
          console.log(data.message);
        }).catch(({ response }) => {
            if (response.status == 401) {
              _this.tokenExpired();
            }
          });

      if(_this.downloadHeightClass != '') {
        $('.cust_scroll').css({'overflow': 'unset'});
        $('.'+_this.downloadHeightClass).css({'height': _this.downloadMaxHeight, 'padding-top': '30px'});
      }
      $('.white_card').css({'box-shadow': 'unset'});
      const el =  _this.$refs[_this.downloadRef];
      const options = {
        type: "dataURL",
        scrollX: 0,
        scrollY: -window.scrollY
      };
      const printCanvas = await html2Canvas(el, options);
      setTimeout(() => {
        const link = document.createElement("a");
        if(_this.downloadType == 'image'){
            link.setAttribute("download", _this.downloadImageName + '.png');
            link.setAttribute(
                "href",
                printCanvas
                .toDataURL("image/png")
                .replace("image/png", "image/octet-stream")
            );
          link.click();
        }
        else if(_this.downloadType == 'document')
        {
            var xlsxSheetData1 = ''; var xlsxSheetName1 = '';
            var xlsxSheetData2 = ''; var xlsxSheetName2 = '';
            var xlsxSheetData3 = ''; var xlsxSheetName3 = '';
            var xlsxSheetData4 = ''; var xlsxSheetName4 = '';
            if(_this.downloadRef == 'TotalActivityByRegionDiv')
            {
              if(_this.activity_region_registrantsCSV.length) {
                xlsxSheetName1 = 'Registrants';
                xlsxSheetData1 = utils.json_to_sheet(_this.activity_region_registrantsCSV);
              }
              if(_this.activity_region_EECSV.length) {
                xlsxSheetName2 = 'Ergo Evals';
                xlsxSheetData2 = utils.json_to_sheet(_this.activity_region_EECSV);
              }
              if(_this.activity_region_SACSV.length) {
                xlsxSheetName3 = 'Self-Assessments';
                xlsxSheetData3 = utils.json_to_sheet(_this.activity_region_SACSV);
              }
            }
            else if(_this.downloadRef == 'ClientActivityDiv')
            {
              if(_this.client_activity_registrantsCSV.length) {
                xlsxSheetName1 = 'Registrants';
                xlsxSheetData1 = utils.json_to_sheet(_this.client_activity_registrantsCSV);
              }
              if(_this.client_activity_saActivityCSV.length) {
                xlsxSheetName2 = 'SA Activity';
                xlsxSheetData2 = utils.json_to_sheet(_this.client_activity_saActivityCSV);
              }
              if(_this.client_activity_evalReportsCSV.length) {
                xlsxSheetName3 = 'Eval Reports';
                xlsxSheetData3 = utils.json_to_sheet(_this.client_activity_evalReportsCSV);
              }
              if(_this.client_activity_ergoEvalCSV.length) {
                xlsxSheetName4 = 'Ergo Eval';
                xlsxSheetData4 = utils.json_to_sheet(_this.client_activity_ergoEvalCSV);
              }
            }
            else if(_this.downloadRef == 'SiteRegistrantsDiv')
            {
              if(_this.site_registrantsUniqueCSV.length) {
                xlsxSheetName2 = 'Current Site Registrants';
                xlsxSheetData2 = utils.json_to_sheet(_this.site_registrantsUniqueCSV);
              }
              if(_this.site_registrantsRevisitsCSV.length) {
                xlsxSheetName1 = 'YTD Site Registrants';
                xlsxSheetData1 = utils.json_to_sheet(_this.site_registrantsRevisitsCSV);
              }
            }
            // A workbook is the name given to an Excel file
            var wb = utils.book_new(); // make Workbook of Excel
            // add Worksheet to Workbook // Workbook contains one or more worksheets
            if(xlsxSheetData1) {
              utils.book_append_sheet(wb, xlsxSheetData1, xlsxSheetName1);
            }
            if(xlsxSheetData2) {
              utils.book_append_sheet(wb, xlsxSheetData2, xlsxSheetName2);   
            }
            if(xlsxSheetData3) {
              utils.book_append_sheet(wb, xlsxSheetData3, xlsxSheetName3);   
            }
            if(xlsxSheetData4) {
              utils.book_append_sheet(wb, xlsxSheetData4, xlsxSheetName4);   
            }
            // export Excel file
            if(wb.SheetNames.length) {
              writeFileXLSX(wb, _this.downloadImageName + '.xlsx') // name of the file is 'book.xlsx'
            }
            ///////////// Commented PDF Code //////////////
            // //Adjust width and height...
            // var topMargin = 20;
            // var leftMargin = 10;
            // var imgWidth =  (printCanvas.width * 60) / 240;
            // var imgHeight = (printCanvas.height * 60) / 240;
            // if(imgWidth < 100){
            //   leftMargin = 50;
            // }
            // else if(imgWidth < 130 && imgWidth > 100){
            //   leftMargin = 30;
            // }
            // else if(imgWidth < 160 && imgWidth > 130){
            //   leftMargin = 20;
            // }
            // //jsPDF changes...
            // var pdf = new jsPDF('p', 'mm', 'a4');
            // pdf.addImage(printCanvas, 'png', leftMargin, topMargin, imgWidth, imgHeight); // 2: 19
            // pdf.save(_this.downloadImageName+`${$('.pdf-title').text()}.pdf`);
        }
        if(_this.downloadHeightClass != '') {
          $('.cust_scroll').css({'overflow': 'auto'});
          $('.'+_this.downloadHeightClass).css({'height': _this.downloadMinHeight, 'padding-top': '0px'});
        }
        $('.white_card').css({'box-shadow': '5px 10px 20px #f198361a'});
        _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
        document.getElementsByClassName("btn-close")[0].click();
      }, 100);
    },
    exportToCsv(filename, rows) {
        var processRow = function (row) {
            var finalVal = '';
            for (var j = 0; j < row.length; j++) {
                var innerValue = row[j] === null ? '' : row[j].toString();
                if (row[j] instanceof Date) {
                    innerValue = row[j].toLocaleString();
                }
                var result = innerValue.replace(/"/g, '""');
                if (result.search(/("|,|\n)/g) >= 0)
                    result = '"' + result + '"';
                if (j > 0)
                    finalVal += ',';
                finalVal += result;
            }
            return finalVal + '\n';
        };
        var csvFile = '';
        for (var i = 0; i < rows.length; i++) {
            csvFile += processRow(rows[i]);
        }
        var blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
        if (navigator.msSaveBlob) { // IE 10+
        navigator.msSaveBlob(blob, filename);
        }
        else {
        var link = document.createElement("a");
        if (link.download !== undefined) { // feature detection
            // Browsers that support HTML5 download attribute
            var url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", filename);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
        }
    },
    updateActivityRegion() {
      document.getElementById("dropdownMenuLink1").click();
      this.filterActivityRegion();
    },
    updateClientsActivity() {
      document.getElementById("dropdownMenuLink2").click();
      this.filterClientsActivity();
    },
    updateSiteRegistrants() {
      document.getElementById("dropdownMenuLink3").click();
      this.filterSiteRegistrants();
    },
    showRegistrantsUnique(){
      this.RegistrantsUnique = true;
      this.RegistrantsRevisits = false;
    },
    showRegistrantsRevisits(){
      this.RegistrantsRevisits = true;
      this.RegistrantsUnique = false;
    },
    downloadSiteRegistrantsBtn(siteData) {
      if(siteData.length == 0) {
        this.hideSiteRegistrantsDownloadBtn = false;
      }
      else {
        this.hideSiteRegistrantsDownloadBtn = true;
      }
    },
    resetDatesMain() {
      this.listConfig.start_date = '';
      this.listConfig.end_date = '';
      this.listConfig.regionBy = '';
      this.listConfig.region_sdate = '';
      this.listConfig.region_edate = '';
      this.listConfig.region_regionBy = '';
      this.listConfig.region_client = '';
      this.listConfig.clients_sdate = '';
      this.listConfig.clients_edate = '';
      this.listConfig.clients_regionBy = '';
      this.listConfig.site_sdate = '';
      this.listConfig.site_edate = '';
      this.listConfig.site_regionBy = '';
      this.isDisabled = true;
      this.isDisabledRegion = true;
      this.isDisabledClients = true;
      this.isDisabledSite = true;
      this.filterActivityRegion();
      this.filterClientsActivity();
      this.filterSiteRegistrants();
      this.mostVisitedData();
      this.mostViewedData();
    },
    resetDatesRegion() {
      this.listConfig.region_sdate = '';
      this.listConfig.region_edate = '';
      this.listConfig.region_regionBy = '';
      this.listConfig.region_client = '';
      this.isDisabledRegion = true;
      this.updateActivityRegion();
    },
    resetDatesClients() {
      this.listConfig.clients_sdate = '';
      this.listConfig.clients_edate = '';
      this.listConfig.clients_regionBy = '';
      this.isDisabledClients = true;
      this.updateClientsActivity();
    },
    resetDatesSite() {
      this.listConfig.site_sdate = '';
      this.listConfig.site_edate = '';
      this.listConfig.site_regionBy = '';
      this.isDisabledSite = true;
      this.updateSiteRegistrants();
    },
    mostVisitedData() {
      let config = {
        method: "post",
        url: process.env.VUE_APP_API_URL + "/api/client-activity-analysis",
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
        },
        data: {request_data : this.encodeAPIRequest(this.listConfig)}, 
      };
      this.$refs.Spinner.modalLoader = true;
      this.showViewedContent = false;
      this.axios(config)
        .then(({ data }) => {
          var decodedJson = this.decodeAPIResponse(data.data);
          if (data.success) {
            this.most_visited_pages = decodedJson.most_visited_pages;
            this.most_viewed_content = decodedJson.most_viewed_content;
            this.$refs.Spinner.modalLoader = false;
            this.showViewedContent = true;
          }
          else {
            this.most_visited_pages = [];
            this.most_viewed_content = [];
            this.$refs.Spinner.modalLoader = false;
          }
        })
        .catch(() => {
          // 
        });
    },
    clientsForActivityRegion() {
      var _this = this;
      let config = {
        method: "get",
        url: process.env.VUE_APP_API_URL + "/api/clients-for-activity-region",
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
        },
      };
      _this.axios(config)
        .then(({ data }) => {
          if (data.success) {
            var decodedJson = _this.decodeAPIResponse(data.records.data);
            _this.client_list.push({ id: null, name: 'All Clients' });
            decodedJson.clients_data.forEach(function (item) {
              _this.client_list.push({ id: item.id, name: item.name });
            });
            _this.region_list.push({ id: 'ALL', name: 'ALL' });
            decodedJson.client_regions.forEach(function (items) {
              _this.region_list.push({ id: items.id, name: items.name });
            });
          }
          else {
            _this.client_list = [];
            _this.region_list = [];
          }
        })
        .catch((response) => {
          console.log(response);
        });
    },
    filterMainData() {
        //Total Activity by Region...
        this.listConfig.region_sdate = this.listConfig.start_date;
        this.listConfig.region_edate = this.listConfig.end_date;
        this.listConfig.region_regionBy = this.listConfig.regionBy;
        //Client Activity...
        this.listConfig.clients_sdate = this.listConfig.start_date;
        this.listConfig.clients_edate = this.listConfig.end_date;
        this.listConfig.clients_regionBy = this.listConfig.regionBy;
        //Site Registrants...
        this.listConfig.site_sdate = this.listConfig.start_date;
        this.listConfig.site_edate = this.listConfig.end_date;
        this.listConfig.site_regionBy = this.listConfig.regionBy;
        
        if (this.listConfig.end_date != "" && this.listConfig.end_date != null) {
          this.changeStartDateRegion();
          this.changeStartDateClients();
          this.changeStartDateSite();
        }
        this.filterActivityRegion();
        this.filterClientsActivity();
        this.filterSiteRegistrants();
        this.mostVisitedData();
        this.mostViewedData();
    },
    filterActivityRegion() {
      var _this = this;
      let config = {
        method: "post",
        url: process.env.VUE_APP_API_URL + "/api/clients-activity-by-region",
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
        },
        data: {request_data : _this.encodeAPIRequest(_this.listConfig)},
      };
      _this.axios(config)
        .then(({ data }) => {
          
          if (data.success) {
            var decodedJson = _this.decodeAPIResponse(data.records.data);

            //Total Activity by Region...
            _this.piechartdatacollection.labels = [];
            _this.piechartdatacollection.datasets[0].data = [];
            _this.activity_region_registrantsCSV = decodedJson.activity_region_registrantsCSV;
            _this.activity_region_EECSV = decodedJson.activity_region_EECSV;
            _this.activity_region_SACSV = decodedJson.activity_region_SACSV;
            _this.activity_region = decodedJson.activity_region;
            _this.activity_region.forEach(function (item) {
              _this.piechartdatacollection.labels.push(item.name + " (" + item.percent + "%)");
              _this.piechartdatacollection.datasets[0].data.push(item.value);
            });
          }
          else {
            _this.activity_region_registrantsCSV = [];
            _this.activity_region_EECSV = [];
            _this.activity_region_SACSV = [];
            _this.activity_region = [];
            _this.piechartdatacollection.labels = [];
            _this.piechartdatacollection.datasets[0].data = [];
          }
          setTimeout(() => {
            if(_this.activity_region.length == 0) {
              _this.hideActivityRegionDownloadBtn = false;
            }
            else {
              _this.hideActivityRegionDownloadBtn = true;
            }
            _this.$refs.appendPieChart.renderChart(
              _this.piechartdatacollection, _this.chart_options
            );
          }, 100);
      })
      .catch(() => {
        // 
      });
    },
    filterClientsActivity() {
      var _this = this;
      let config = {
        method: "post",
        url: process.env.VUE_APP_API_URL + "/api/clients-activity-data",
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
        },
        data: {request_data : _this.encodeAPIRequest(_this.listConfig)},
      };
      _this.axios(config)
        .then(({ data }) => {
          if (data.success) {
            var decodedJson = _this.decodeAPIResponse(data.records.data);
            //Registrants...
            _this.registrantsdatacollection.labels = [];
            _this.registrantsdatacollection.datasets[0].data = [];
            _this.registrantsdatacollection.datasets[0].backgroundColor = [];
            _this.client_activity_registrantsCSV = decodedJson.client_activity_registrantsCSV;
            _this.registrants_data = decodedJson.registrants;
            _this.registrants_data.forEach(function (item) {
              // _this.registrantsdatacollection.labels.push(item.name + " (" + item.percent + "%)");
              _this.registrantsdatacollection.labels.push(item.name);
              _this.registrantsdatacollection.datasets[0].data.push(parseInt(item.value.replaceAll(',','')));
              _this.registrantsdatacollection.datasets[0].backgroundColor.push("#F3C695");
            });
            _this.registrantsdatacollection.datasets[0].data.push(0);
            //SA Activity...
            _this.saActivitydatacollection.labels = [];
            _this.saActivitydatacollection.datasets[0].data = [];
            _this.saActivitydatacollection.datasets[0].backgroundColor = [];
            _this.client_activity_saActivityCSV = decodedJson.client_activity_saActivityCSV;
            _this.saActivity_data = decodedJson.saActivity;
            _this.saActivity_data.forEach(function (item) {
              // _this.saActivitydatacollection.labels.push(item.name + " (" + item.percent + "%)");
              _this.saActivitydatacollection.labels.push(item.name);
              _this.saActivitydatacollection.datasets[0].data.push(parseInt(item.value.replaceAll(',','')));
              _this.saActivitydatacollection.datasets[0].backgroundColor.push('#F3C695');
            });
            _this.saActivitydatacollection.datasets[0].data.push(0);
            //Eval Reports...
            _this.evalReportsdatacollection.labels = [];
            _this.evalReportsdatacollection.datasets[0].data = [];
            _this.evalReportsdatacollection.datasets[0].backgroundColor = [];
            _this.client_activity_evalReportsCSV = decodedJson.client_activity_evalReportsCSV;
            _this.evalReports_data = decodedJson.evalReports;
            _this.evalReports_data.forEach(function (item) {
              // _this.evalReportsdatacollection.labels.push(item.name + " (" + item.percent + "%)");
              _this.evalReportsdatacollection.labels.push(item.name);
              _this.evalReportsdatacollection.datasets[0].data.push(parseInt(item.value.replaceAll(',','')));
              _this.evalReportsdatacollection.datasets[0].backgroundColor.push('#F3C695');
            });
            _this.evalReportsdatacollection.datasets[0].data.push(0);
            //Ergo Eval...
            _this.ergoEvaldatacollection.labels = [];
            _this.ergoEvaldatacollection.datasets[0].data = [];
            _this.ergoEvaldatacollection.datasets[0].backgroundColor = [];
            _this.client_activity_ergoEvalCSV = decodedJson.client_activity_ergoEvalCSV;
            _this.ergoEval_data = decodedJson.ergoEval;
            _this.ergoEval_data.forEach(function (item) {
              // _this.ergoEvaldatacollection.labels.push(item.name + " (" + item.percent + "%)");
              _this.ergoEvaldatacollection.labels.push(item.name);
              _this.ergoEvaldatacollection.datasets[0].data.push(parseInt(item.value.replaceAll(',','')));
              _this.ergoEvaldatacollection.datasets[0].backgroundColor.push('#F3C695');
            });
            _this.ergoEvaldatacollection.datasets[0].data.push(0);
            setTimeout(() => {
              if(_this.clientActivityTabName == 'Client-Activity-Registrants') {
                this.setClientActivityData(_this.clientActivityTabName, _this.registrants_data);
              }
              else if(_this.clientActivityTabName == 'Client-Activity-SA-Activity') {
                this.setClientActivityData(_this.clientActivityTabName, _this.saActivity_data);
              }
              else if(_this.clientActivityTabName == 'Client-Activity-Eval-Reports') {
                this.setClientActivityData(_this.clientActivityTabName, _this.evalReports_data);
              }
              else if(_this.clientActivityTabName == 'Client-Activity-Ergo-Eval') {
                this.setClientActivityData(_this.clientActivityTabName, _this.ergoEval_data);
              }
              var setChartWidth = _this.barChartOptions;
              //Registrants...
              if(_this.registrants_data.length) {
                setChartWidth = (_this.registrants_data.length < 2) ? _this.chart_options : _this.barChartOptions;
                _this.$refs.appendRegistrants.renderChart(
                  _this.registrantsdatacollection, setChartWidth
                );
              }
              //SA Activity...
              if(_this.saActivity_data.length) {
                setChartWidth = (_this.saActivity_data.length < 2) ? _this.chart_options : _this.barChartOptions;
                _this.$refs.appendsaActivity.renderChart(
                  _this.saActivitydatacollection, setChartWidth
                );
              }
              //Eval Reports...
              if(_this.evalReports_data.length) {
                setChartWidth = (_this.evalReports_data.length < 2) ? _this.chart_options : _this.barChartOptions;
                _this.$refs.appendevalReports.renderChart(
                  _this.evalReportsdatacollection, setChartWidth
                );
              }
              //Ergo Eval...
              if(_this.ergoEval_data.length) {
                setChartWidth = (_this.ergoEval_data.length < 2) ? _this.chart_options : _this.barChartOptions;
                _this.$refs.appendergoEval.renderChart(
                  _this.ergoEvaldatacollection, setChartWidth
                );
              }
            }, 100);
          }
          else {
            //Registrants...
            _this.registrants_data = [];
            _this.registrantsdatacollection.labels = [];
            _this.registrantsdatacollection.datasets[0].data = [];
            _this.registrantsdatacollection.datasets[0].backgroundColor = [];
            //SA Activity...
            _this.saActivity_data = [];
            _this.saActivitydatacollection.labels = [];
            _this.saActivitydatacollection.datasets[0].data = [];
            _this.saActivitydatacollection.datasets[0].backgroundColor = [];
            //Eval Reports...
            _this.evalReports_data = [];
            _this.evalReportsdatacollection.labels = [];
            _this.evalReportsdatacollection.datasets[0].data = [];
            _this.evalReportsdatacollection.datasets[0].backgroundColor = [];
            //Ergo Eval...
            _this.ergoEval_data = [];
            _this.ergoEvaldatacollection.labels = [];
            _this.ergoEvaldatacollection.datasets[0].data = [];
            _this.ergoEvaldatacollection.datasets[0].backgroundColor = [];
            setTimeout(() => {
              this.setClientActivityData(_this.clientActivityTabName, _this.registrants_data);
              //Registrants...
              _this.$refs.appendRegistrants.renderChart(
                _this.registrantsdatacollection, _this.barChartOptions
              );
              //SA Activity...
              _this.$refs.appendsaActivity.renderChart(
                _this.saActivitydatacollection, _this.barChartOptions
              );
              //Eval Reports...
              _this.$refs.appendevalReports.renderChart(
                _this.evalReportsdatacollection, _this.barChartOptions
              );
              //Ergo Eval...
              _this.$refs.appendergoEval.renderChart(
                _this.ergoEvaldatacollection, _this.barChartOptions
              );
            }, 100);
          }
      })
      .catch(() => {
        // 
      });
    },
    filterSiteRegistrants() {
      var _this = this;
      _this.$parent.$refs.fullpageloader.is_full_page_loader = true;
      let config = {
        method: "post",
        url: process.env.VUE_APP_API_URL + "/api/clients-site-registrants",
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
        },
        data: {request_data : _this.encodeAPIRequest(_this.listConfig)},
      };
      _this.axios(config)
        .then(({ data }) => {
          if (data.success) {
            _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
            var decodedJson = _this.decodeAPIResponse(data.records.data);
            //Site Registrants...
            _this.site_registrants_unique = decodedJson.site_registrants_unique;
            _this.site_registrants_revisits = decodedJson.site_registrants_revisits;
            _this.siteUniqueCollection.labels = [];
            _this.siteUniqueCollection.datasets[0].data = [];
            _this.site_registrantsUniqueCSV = decodedJson.site_registrantsUniqueCSV;
            _this.site_registrantsUnique = decodedJson.site_registrantsUnique;
            _this.site_registrantsUnique.forEach(function (item) {
              _this.siteUniqueCollection.labels.push(item.name);
              _this.siteUniqueCollection.datasets[0].data.push(item.value);
            });
            _this.siteRevisitsCollection.labels = [];
            _this.siteRevisitsCollection.datasets[0].data = [];
            _this.site_registrantsRevisitsCSV = decodedJson.site_registrantsRevisitsCSV;
            _this.site_registrantsRevisits = decodedJson.site_registrantsRevisits;
            _this.site_registrantsRevisits.forEach(function (item) {
              _this.siteRevisitsCollection.labels.push(item.name);
              _this.siteRevisitsCollection.datasets[0].data.push(item.value);
            });
          }
          else {
            _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
            _this.site_registrants_unique = 0;
            _this.site_registrants_revisits = 0;
            _this.site_registrantsUniqueCSV = [];
            _this.site_registrantsUnique = [];
            _this.siteUniqueCollection.labels = [];
            _this.siteUniqueCollection.datasets[0].data = [];
            _this.site_registrantsRevisitsCSV = [];
            _this.site_registrantsRevisits = [];
            _this.siteRevisitsCollection.labels = [];
            _this.siteRevisitsCollection.datasets[0].data = [];
          }
          setTimeout(() => {
            _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
            this.downloadSiteRegistrantsBtn(_this.site_registrantsRevisits);
            _this.$refs.appendSiteUnique.renderChart(
              _this.siteUniqueCollection,_this.chart_options
            );
            _this.$refs.appendSiteRevisits.renderChart(
              _this.siteRevisitsCollection, _this.chart_options
            );
          }, 100);
        })
        .catch(() => {
          //
          _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
        });
    },
    updateDatePicker(comeFrom) {
      // Extend dataTables search
      $.fn.dataTable.ext.search.push(function (settings, data) {
        var min = $(".min-date-value").html();
        var max = $(".max-date-value").html();
        var createdAt = data[0] || 0; // Our date column in the table
        if (
          min == "" ||
          max == "" ||
          (moment(createdAt).isSameOrAfter(min) &&
          moment(createdAt).isSameOrBefore(max))
        ) {
          return true;
        }
        return false;
      });
      if (comeFrom == 'Main') {
        this.filterMainData();
      }
    },
    // changeStartDate() {
    //   this.$nextTick(() => {
    //     this.isDisabled = false;
    //     this.disabledDates = {
    //       to: new Date(this.listConfig.start_date - 8640000),
    //       from: new Date(Date.now()),
    //     };
    //     if (this.listConfig.end_date != "" && this.listConfig.end_date != null) {
    //       this.updateDatePicker('Main');
    //     }
    //   });
    // },
    changeStartDate(newStartDate) {
      this.$nextTick(() => {
        const startDate = new Date(newStartDate);
        const endDate = this.listConfig.end_date ? new Date(this.listConfig.end_date) : null;

        if (endDate && startDate > endDate) {
          this.$toast.error('"From" date cannot be greater than the "To" date.', {
              position: "top-right",
              duration: 5000,
            });
          this.listConfig.start_date = this.previousStartDate;
        } else {
          this.previousStartDate = newStartDate;

          // Old changeStartDate code
          this.isDisabled = false;
          this.disabledDates = {
            to: new Date(this.listConfig.start_date - 8640000),
            from: new Date(Date.now()),
          };
          if (this.listConfig.end_date != "" && this.listConfig.end_date != null) {
            this.updateDatePicker('Main');
          }
        }
      });
    },
    changeEndDate() {
      this.$nextTick(() => {
        this.updateDatePicker('Main');
      });
    },
    changeStartDateRegion() {
      this.$nextTick(() => {
        this.isDisabledRegion = false;
        this.disabledDates = {
          to: new Date(this.listConfig.region_sdate - 8640000),
          from: new Date(Date.now()),
        };
        if (this.listConfig.region_edate != "" && this.listConfig.region_edate != null) {
          this.updateDatePicker('');
        }
      });
    },
    changeEndDateRegion() {
      this.$nextTick(() => {
        this.updateDatePicker('');
      });
    },
    changeStartDateClients() {
      this.$nextTick(() => {
        this.isDisabledClients = false;
        this.disabledDates = {
          to: new Date(this.listConfig.clients_sdate - 8640000),
          from: new Date(Date.now()),
        };
        if (this.listConfig.clients_edate != "" && this.listConfig.clients_edate != null) {
          this.updateDatePicker('');
        }
      });
    },
    changeEndDateClients() {
      this.$nextTick(() => {
        this.updateDatePicker('');
      });
    },
    changeStartDateSite() {
      this.$nextTick(() => {
        this.isDisabledSite = false;
        this.disabledDates = {
          to: new Date(this.listConfig.site_sdate - 8640000),
          from: new Date(Date.now()),
        };
        if (this.listConfig.site_edate != "" && this.listConfig.site_edate != null) {
          this.updateDatePicker('');
        }
      });
    },
    changeEndDateSite() {
      this.$nextTick(() => {
        this.updateDatePicker('');
      });
    },
    customFormatter(date) {
      return moment(date).format("MMM DD, YYYY");
    },
  },
};
</script>